import { Grid, Paper, Skeleton, Typography } from "@mui/material";
import { DashboardInfo } from "../../../home/components/dashboard-info/DashboardInfo";
import { BarChart } from "./bar-chart/BarChart";
import { TopSkillsTable } from "./top-skills-table/TopSkillsTable";
import { TopSkillsGroup } from "./top-skills-group/TopSkillsGroup";
import { SkillsPieChart } from "./skills-pie-chart/SkillsPieChart";
import { SkillsContext } from "../../contexts/SkillsContext";
import { useContext, useEffect, useState } from "react";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";
import { useAPI } from "../../../../shared/services/api/API";
import { useTranslation } from "react-i18next";
import "./SkillOverview.scss";

export const SkillOverview = () => {
  const { updateTimeSpentSkillLessMore, updateSkillsGainSkillLessMore, updateValidateLessMore } = useContext(SkillsContext);
  const { httpGet, handleAlertBar } = useAPI();
  const [ overviewData, setOverviewData] = useState<any>({
    "totalSkills": '-',
    "validatedSkills": '-',
    "skillNotValidated": '-',
    "skillsGainedLastMonth": '-',
    "skillsGainedThisMonth": '-',
    "timeSpentLearningInMinutes": 0,
    "formatTimeSpent": 0
});
  const { t } = useTranslation();

  useEffect(() => {
    getSkillsOverview();
  }, []);

  const timeDisplay = (totalMinutes: any) => {
    const days = Math.floor(totalMinutes / 1440);
    const hours = Math.floor((totalMinutes % 1440) / 60);
    const minutes = totalMinutes % 60;
    return `${days}d ${hours}hr ${minutes}mins`;
  }

  const getSkillsOverview = () => {
    httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_SKILLS_OVERVIEW).then((response)=>{
        if(response && response.data) {
            if(response.data.error) {
                handleAlertBar('error', response.data.message);
            } else {
              const res = response.data;
              const formateTimeSpentLearningInMinutes = timeDisplay(res.timeSpentLearningInMinutes);
              for(const key in res) {
                if(res[key] === 0 || res[key] === null ) {
                  res[key] = '-';
                }
              }
              res['formatTimeSpent'] = formateTimeSpentLearningInMinutes;
              setOverviewData(res);
            }
        } else {
            handleAlertBar("error", t("opva.serviceUnavailable"));
          }
    })
  }
  return (
    <div className="skills-overview-container">
      {overviewData['totalSkills'] === (0 || '-') ? <div className="overview-mask-container">
        <div className="content">
          <Typography variant="h1" className="mt-5" textAlign={'center'}>
            {t("opva.noSkillsOverviewMsg")}
          </Typography>
        </div>
      </div> : null}
      <Grid container className="mt-1" spacing={5}>
        <Grid item xs={12} md={3}>
          <DashboardInfo
            title={t("opva.noOfSkills")}
            infoName={overviewData.totalSkills}
            iconName="RectangleHash.png"
            bgColorName="harp"
            action={() => updateValidateLessMore(true)}
            isBorder={true}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DashboardInfo
            title={t("opva.timeSpentLearnSkill")}
            infoName={overviewData.formatTimeSpent}
            iconName="Clock.svg"
            bgColorName="green"
            action={() => updateTimeSpentSkillLessMore(true)}
            isBorder={true}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DashboardInfo
            title={`${t("opva.validated")} : ${t("opva.unvalidated")}`}
            infoName={`${overviewData.validatedSkills} : ${overviewData.skillNotValidated}`}
            iconName="blueHash.png"
            bgColorName="blue"
            action={() => updateValidateLessMore(true)}
            isBorder={true}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DashboardInfo
            title={`${t("opva.skillGainLast")} : ${t("opva.thisMonth")}`}
            infoName={`${overviewData.skillsGainedLastMonth} : ${overviewData.skillsGainedThisMonth}`}
            iconName="calender.png"
            bgColorName="green"
            action={() => updateSkillsGainSkillLessMore(true)}
            isBorder={true}
          />
        </Grid>
      </Grid>
      <Grid container className="mt-1" spacing={3}>
        <Grid item xs={12} md={7}>
          <BarChart />
          <Grid container className="mt-2 mb-2" spacing={2}>
            <Grid item xs={12} sm={12} md={4}>
              <Paper
                sx={{
                  height: "222px",
                  backgroundColor: "#e6edef",
                  borderRadius: "16px",
                  position:"relative"
                }}
              >
                {/* <img src={"images/OPVA.png"} alt="logo" style={{position: 'absolute', bottom: '10px', right: '10px'}}></img> */}
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={8} sx={{height: "222px"}}>
              <SkillsPieChart />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={5}>
          <TopSkillsTable />
          <br></br>
          <TopSkillsGroup />
        </Grid>
      </Grid>
    </div>
  );
};
