import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';

export const CreateWorkflowDialog = (Props: any) => {
    const { t } = useTranslation();
  return (
    <Dialog open={Props?.openDialog} onClose={Props?.handleCloseDialog}>
        <DialogTitle>{t("opva.create_Workflow")}</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          id="name"
          label={t("opva.title")}
          type="text"
          variant="standard"
          value={Props?.workflowObj.workflowName}
          required={Props?.workflowObj.workflowName.length === 0}
          fullWidth
          onChange={(e) =>
            Props?.setWorkflowObj({
              workflowName: e.target.value,
              workflowDescription: Props?.workflowObj.workflowDescription,
            })
          }
          error={
            Props?.workflowObj.workflowName.length >
            35
          }
          helperText={
            Props?.workflowObj.workflowName.length >
            35 && t("opva.workflowNameLength")
          }
        />
        <TextField
          margin="dense"
          id="description"
          label={t("opva.description")}
          type="text"
          variant="standard"
          value={Props?.workflowObj.workflowDescription}
          required={Props?.workflowObj.workflowDescription.length === 0}
          fullWidth
          multiline
          rows={3}
          onChange={(e) =>
            Props?.setWorkflowObj({
              workflowName: Props?.workflowObj.workflowName,
              workflowDescription: e.target.value,
            })
          }
          error={
            Props?.workflowObj.workflowDescription.length >
            200
          }
          helperText={
            Props?.workflowObj.workflowDescription.length >
              200 && t("opva.workflowDescriptionLength")
          }
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={Props?.handleCloseDialog}>
        {t("opva.cancel")}
        </Button>
        <Button
          onClick={Props?.handleCreateWorkflow}
          color="primary"
          variant="contained"
          disabled={
            Props?.workflowObj.workflowName.length === 0 ||
            Props?.workflowObj.workflowDescription.length === 0 ||
            Props?.workflowObj.workflowName.trim() === "" ||
            Props?.workflowObj.workflowDescription.trim() === '' ||
            Props?.workflowObj.workflowName.length >
              35 ||
            Props?.workflowObj.workflowDescription.length >
              200
          }
        >
          {t("opva.copyAsWorkflow")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}