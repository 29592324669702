import "./Home.scss";
import { Grid, Link, Typography } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import PrintIcon from "@mui/icons-material/Print";
import { DashboardInfo } from "./dashboard-info/DashboardInfo";
import { DashboardChart } from "./dashboard-chart/DashboardChart";
import { DashboardGroups } from "./dashboard-groups/DashboardGroups";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { AuthenticationContext } from "../../../shared/Contexts";
import { useAPI } from "../../../shared/services/api/API";
import { OPTIVAL_CONSTANTS } from "../../../shared/Constants";
import { SplineCharts } from "../../charts/line-charts/SplineCharts";
import { BarChart } from "../../charts/column-bar-charts/bar-chart/BarChart";
import { PieChart } from "../../charts/pie-chart/PieChart";
import { CustomChart } from "../../charts/custom-chart/CustomChart";

export const Home = () => {
  const { t } = useTranslation(); 
  const { authUserDetails } = useContext(AuthenticationContext);
  const { httpGet, handleAlertBar } = useAPI();
  const [workflowCreationMonthlyData, setWorkflowCreationMonthlyData] = useState<any>([]);
  const [workflowSkillsData, setWorkflowSkillsData] = useState<any>([]);
  const [workflowSkills, setWorkflowSkills] = useState([]);
  const workflowMonthlyCategory = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]
  const colorList = ['#0E687A','#7DDFC3','#0084F4','#25BCCA','#00C48C','#81AF87'];
  const [ overviewData, setOverviewData] = useState<any>({});

  useEffect(() => {
    getDashboardOverview();
    getWorkflowByMonthChartData();
    getWorkflowBySkillsChartData();
  }, []);

  const getDashboardOverview = () => {
    httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_DASHBOARD_OVERVIEW).then((response)=>{
        if(response && response.data) {
            if(response.data.error) {
                handleAlertBar('error', response.data.message);
            } else {
              const res = response.data;
              for(const key in res) {
                if(res[key] === 0 || res[key] === null ) {
                  res[key] = '-';
                }
              }
              setOverviewData(res);
            }
        } else {
            handleAlertBar("error", t("opva.serviceUnavailable"));
          }
    })
  }

  const getWorkflowBySkillsChartData = () => {
    httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_WORKFLOW_BY_SKILLS).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar("error", response.data.message);
          } else {
            // set bar chart categories
            const skillName = response?.data?.map(
              (skill: any) => skill.skillName
            );
            setWorkflowSkills(skillName);

            //set series data
            const workflowCount = response?.data.map(
              (item: any) => item.workflowCount
            );

            setWorkflowSkillsData([
              {
                name: t("opva.workflowCount"),
                data: workflowCount?.map((value: string,index: number) => ({
                  y: value,
                  color: colorList[index]
                }))
              }
            ]);
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      }
    );
  };

  const getWorkflowByMonthChartData = () => {
    httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_WORKFLOW_CREATION_MONTHLY).then(
      (response) => {
        if (response && response.data) {
          if (response.data.error) {
            handleAlertBar("error", response.data.message);
          } else {
            //set series data
            const seriesData= transformData(response?.data);
            setWorkflowCreationMonthlyData([
              {
                name: t("opva.workflowCount"),
                data: seriesData?.map((value: string,index: number) => ({
                  y: value,
                  color: colorList[index]
                }))
              }
            ]);
          }
        } else {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        }
      }
    );
  };

  const transformData = (data: any) =>{
    const resp: any = [];
    for (const [key, value] of Object.entries(data)) {
      const [month, year] = key.split('-');
      resp?.push(value);
    }
    return resp;
  } 

  return (
    <div className="home-container bg-light-blue px-4 pt-3">
      {/* {!overviewData?.monthlyActivity && <div className="dashboard-mask-container">
        <div className="content">
          <Typography variant="h3">{`${authUserDetails?.organizationName} ${t("opva.dashboard")}`}</Typography>
          <Typography variant="h1" className="mt-5">
            {t("opva.dashboardWillPopulate")}
          </Typography>
        </div>
      </div>} */}
      <Grid
        container
        sx={{
          alignItems: "center",
        }}
      >
        <Grid item xs={6} md={6}>
          <Typography variant="h3" className="mb-0">
          {`${authUserDetails?.organizationName} ${t("opva.dashboard")}`}
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          md={6}
          sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}
        >
          <Link component="button" title="Share" className="me-4" disabled={true}>
            <ShareIcon color="disabled"/>
          </Link>
          <Link component="button" title="Print" disabled={true}>
            <PrintIcon color="disabled"/>
          </Link>
        </Grid>
      </Grid>
      <Grid container className="mt-0" spacing={5}>
        <Grid item xs={12} md={3}>
          <DashboardInfo
            title="opva.monthlyActivity"
            infoName={overviewData?.monthlyActivity?.count}
            infoSubName={overviewData?.monthlyActivity?.percentageChange}
            bgColorName="crystalBlue"
            isBorder={true}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DashboardInfo
            title="opva.workflowsDeployed"
            infoName={overviewData?.workflowsDeployed?.count}
            infoSubName={overviewData?.workflowsDeployed?.percentageChange}
            bgColorName="harp"
            isBorder={true}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DashboardInfo
            title="opva.newUsers"
            infoName={overviewData?.newUsers?.count}
            infoSubName={overviewData?.newUsers?.percentageChange}
            bgColorName="crystalBlue"
            isBorder={true}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DashboardInfo
            title="opva.skillsAdded"
            infoName={overviewData?.skillsAdded?.count}
            infoSubName={overviewData?.skillsAdded?.percentageChange}
            bgColorName="harp"
            isBorder={true}
          />
        </Grid>
      </Grid>

      <Grid container className="mt-1" spacing={2.5}>
        <Grid item xs={12} md={5}>
          <SplineCharts />
        </Grid>
        <Grid item xs={12} md={2}>
          <CustomChart/>
        </Grid>
        <Grid item xs={12} md={5}>
          <BarChart title={t("opva.workflowBySkills")} category={workflowSkills} seriesData={workflowSkillsData}/>
        </Grid>
      </Grid>
      <Grid container className="mt-1 mb-1" spacing={2.5}>
        <Grid item xs={12} md={5}>
          <PieChart />
        </Grid>
        <Grid item xs={12} md={7}>
          <BarChart title={t("opva.workflowCreationByMonth")} category={workflowMonthlyCategory} seriesData={workflowCreationMonthlyData}/>
        </Grid>
      </Grid>
    </div>
  );
};
