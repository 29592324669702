import React, { useEffect, useState } from 'react';
import './CustomChart.scss'
import { useTranslation } from 'react-i18next';
import { useAPI } from '../../../shared/services/api/API';
import { OPTIVAL_CONSTANTS } from '../../../shared/Constants';
import { Typography } from '@mui/material';

export const CustomChart = () => {
    const { t } = useTranslation();
    const [topSkills,setTopSkills] = useState<any>([]);
    const [skills,setSkills] = useState<any>([]);
    const [skillLevels,setSkillLevels] = useState<any>([]);

    const { httpGet, handleAlertBar } = useAPI();
    const fetchTopSkillsWithCount = () => {
        const queryParam = {
            count: 6
        }
        httpGet(OPTIVAL_CONSTANTS.API_URLS.GET_SKILLS_WITH_COUNT, queryParam).then(
            (response) => {
              if (response && response.data) {
                if (response.data.error) {
                  handleAlertBar("error", response.data.message);
                } else {
                    setSkillLevels(response?.data?.skillLevels);
                    setSkills(response?.data?.skills);
                    setTopSkills(response.data?.topSkills);
                }
              } else {
                handleAlertBar("error", t("opva.serviceUnavailable"));
              }
            }
          );
    }

    const calculateProgress = (count: number) => {
        const maxCount = 10;
        return (count/maxCount) * 100;
    }

    useEffect(() => {
        fetchTopSkillsWithCount(); 
    },[])
  return (
    <div className='custom-chart-container p-4'>
        <div>
            <span>Top Skills</span>
        </div>
        <table className='mt-3' style={{width: "100%"}}>
            <tbody className='mt-3'>
                {topSkills?.map((topSkill: any) => {
                    const skill = skills?.find((s: any) => s?.id === topSkill?.skillId);
                    return (
                        <tr key={skill?.id} style={{border: "none", marginTop: "20px", marginBottom: "20px", height: "40px"}}>
                            <td style={{textAlign: "left", marginBottom: "12px", marginTop: "12px"}}>
                            <div
                              style={{ width: "50px" }}
                            >
                              <Typography style={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}} title={skill?.name ? skill?.name : "-"}>{skill?.name ? skill?.name : "-"}</Typography>
                            </div>
                            </td>
                            {skillLevels?.map((level: any)=>{
                                const levelData = topSkill?.levels?.find((l: any) => l?.id === level?.id);
                                const progress = levelData ? calculateProgress(levelData?.currentCount) : 0;

                                return (
                                    <td key={level?.id} style={{marginBottom: "12px", marginTop: "12px", textAlign: "center"}}>
                                        <div style={{width: "22px", background: "#f0f0f0", borderRadius: "8px"}}>
                                            <div
                                            style={{
                                                width: `${progress}%`,
                                                background: '#1C1C1C',
                                                height: '4px',
                                                borderRadius: '8px',
                                                transition: "width 0.3s rase-in-out"
                                            }}
                                            >
                                            </div>

                                        </div>
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
            </tbody>

        </table>

    </div>
  )
}