import { Typography } from "@mui/material";
import React from "react";

export const OpenApp = () => {
  const [isMobile] = React.useState<boolean>(() => {
    return /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
  });

  React.useEffect(() => {
    window.location.href = "opva://opva/openApp" + window.location.search;
  }, []);

  if (!isMobile) {
    return (
      <div>
        <Typography variant="body1">
          Please open the link in your mobile device.
        </Typography>
      </div>
    );
  }

  return (
    <div>
      <Typography variant="body1">Redirecting..</Typography>
      <Typography variant="body1">
        If the app does not open automatically, please click on the link below.
      </Typography>
      <a href={"opva://opva/openApp" + window.location.search}>Click here</a>
    </div>
  );
};
