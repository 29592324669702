import { useContext, useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Link,
  Menu,
  MenuItem,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CloseIcon from "@mui/icons-material/Close";
import { remove } from "lodash";
import "./Assign.scss";
import {
  CollapseRowProps,
  WorkflowAssignGroups,
  WorkflowAssignGroupsModel,
  WorkflowAssignGroupsUsersModel,
  WorkflowAssignUsersModel,
  WorkflowConstants,
  WorkflowListModel,
} from "../../models/Workflow";
import { AssignLoading } from "./AssignLoading";
import { useAPI } from "../../../../shared/services/api/API";
import { SpinnerContext } from "../../../../shared/SpinnerContext";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";
import { updateURLParams } from "../../../../shared/utils/Utils";
import { AlertDialog } from "../../../../shared/components/alert-dialog/AlertDialog";
import { AuthenticationContext } from "../../../../shared/Contexts";
import { useTranslation } from "react-i18next";

export const Assign = (props: CollapseRowProps) => {
  const { authUserDetails } = useContext(AuthenticationContext);
  const { httpGet, httpPost, httpDelete, handleAlertBar } = useAPI();
  const { isSpinnerShow } = useContext(SpinnerContext);
  const [groupsList, setGroupsList] = useState<WorkflowAssignGroupsUsersModel[]>([]);
  const [assignedGroupsList, setAssignedGroupsList] = useState<
  WorkflowAssignGroupsModel[]
  >([]);
  const [assignedUsersList, setAssignedUsersList] = useState<
  WorkflowAssignUsersModel[]
  >([]);
  const [selectedGroupId, setSelectedGroupId] = useState<number>(0);
  const [selecteduserId, setSelectedUserId] = useState<number>(0);
  const [searchGroupName, setSearchGroupName] = useState<string>("");
  const [groupId, setGroupId] = useState<number>(0);
  const [userId, setUserId] = useState<number>(0);
  const [isGroupAlertDialog, setIsGroupAlertDialog] = useState(false);
  const [isUserAlertDialog, setIsUserAlertDialog] = useState(false);
  const { t } = useTranslation();
  const handleAlertGroupDialogOpen = () => {
    setIsGroupAlertDialog(true);
    setGroupAnchorEl(null);
  };

  const handleAlertUserDialogOpen = () => {
    setIsUserAlertDialog(true);
    setUserAnchorEl(null);
  };

  const [anchorGroupEl, setGroupAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorUserEl, setUserAnchorEl] = useState<null | HTMLElement>(null);

  const openGroup = Boolean(anchorGroupEl);
  const openUser = Boolean(anchorUserEl);
  const handleGroupClick = (event: React.MouseEvent<HTMLElement>, groupId: number) => {
    setGroupId(groupId);
    setGroupAnchorEl(event.currentTarget);
  };
  const handleGroupClose = () => {
    setGroupAnchorEl(null);
  };
  const handleUserClick = (event: React.MouseEvent<HTMLElement>, empId: number) => {
    setUserId(empId);
    setUserAnchorEl(event.currentTarget);
  };
  const handleUserClose = () => {
    setUserAnchorEl(null);
  };

  const handleAlertGroupDialogClose = () => {
    setGroupId(0);
    setIsGroupAlertDialog(false);
  };

  const handleAlertUserDialogClose = () => {
    setUserId(0);
    setIsUserAlertDialog(false);
  };

  const handleRemoveAssignedGroup = () => {
    const urlParams = {
      workflowId: props.workflowId,
    };
    const reqBody = {
      employeeIds: userId ? [userId] : [],
      groupIds: groupId ? [groupId] : []
    };
    httpDelete(
      updateURLParams(
        OPTIVAL_CONSTANTS.API_URLS.REMOVE_ASSIGNED_WORKFLOW_GROUPS,
        urlParams
      ), reqBody
    ).then(
      (response) => {
        if (response && response.data && response.data.error) {
          handleAlertBar("error", t("opva.serviceUnavailable"));
        } else {
          if(groupId) {
            handleAlertBar(
              "success",
              t("opva.assignedWorkflowGroupRemovedSuccessfully.")
            );

          } else {
            handleAlertBar(
              "success",
              t("opva.assignedWorkflowUserRemovedSuccessfully.")
            );
          }
          getAssignedGroups(props.workflowId);
          updateAssignedGroupsList();
          updateAssignedUsersList();
          updateAssignedGroupsData(-1);
          handleAlertGroupDialogClose();
          handleAlertUserDialogClose();
        }
      },
      (error) => {}
    );
  };

  const handleAddWorkflowToGroups = () => {
    const urlParams = {
      organizationId: authUserDetails.organizationId,
      workflowId: props.workflowId,
    };
    const queryParams = {
      groupIds: selectedGroupId || '',
      employeeIds: selecteduserId || '',
    };
    httpPost(
      updateURLParams(
        OPTIVAL_CONSTANTS.API_URLS.ASSIGN_WORKFLOW_TO_GROUPS,
        urlParams
      ),
      null,
      queryParams
    ).then(
      (response) => {
        if (response && response.data && response.data.error) {
          const errorMsg =
            response?.data?.status === 400
              ? OPTIVAL_CONSTANTS.ERROR_CODE_MSG
                  .WORKFLOW_ALREADY_ASSIGNED_TO_GROUP
              : t("opva.serviceUnavailable");
          handleAlertBar("error", errorMsg);
        } else {
          if(selectedGroupId) {
            handleAlertBar(
              "success",
              t("opva.workflowAssignedGroupSuccessfully.")
            );
          } else {
            handleAlertBar(
              "success",
              t("opva.workflowAssignedUserSuccessfully.")
            );
          }
          setSearchGroupName("");
          setSelectedGroupId(0);
          setSelectedUserId(0);
          updateAssignedGroupsData(1);
          getAssignedGroups(props.workflowId);
        }
      },
      (error) => {}
    );
  };

  function getAssignedGroups(workflowId: number) {
    const urlParams = {
      workflowId: workflowId,
    };
    httpGet(
      updateURLParams(
        OPTIVAL_CONSTANTS.API_URLS.WORKFLOW_ASSIGNED_GROUPS,
        urlParams
      )
    ).then(
      (response) => {
        if (response && response.data) {
          setAssignedGroupsList(response?.data?.groups);
          setAssignedUsersList(response?.data?.employees);
        }
      },
      (error) => {}
    );
  }

  function updateAssignedGroupsData(count: number) {
    if (
      props.workflowData &&
      (props.workflowData?.groupsAssigned ||
        props.workflowData?.groupsAssigned > -1)
    ) {
      props.workflowData.groupsAssigned += count;
    }
  }

  function updateAssignedGroupsList() {
    remove(assignedGroupsList, function (group: WorkflowAssignGroupsModel) {
      return group?.id === groupId;
    });
  }

  function updateAssignedUsersList() {
    remove(assignedUsersList, function (emp: WorkflowAssignUsersModel) {
      return emp?.id === userId;
    });
  }

  useEffect(() => {
    getAssignedGroups(props.workflowId);
  }, [props.workflowId]);

  useEffect(() => {
      fetchGroupUsersList('');
  }, [assignedUsersList, assignedGroupsList]);

  const fetchGroupUsersList = useMemo(
    () =>
      debounce((searchUser: string) => {
        const queryParams = {
          query: searchUser,
        };
        const alreadySelectedGroupId = assignedGroupsList.length > 0 ? (assignedGroupsList?.map((e: any) => e?.id)) : [];
        const alreadySelectedUserId = assignedUsersList?.map((e: any) => e?.id);
        const requestBody = {
            userIds: alreadySelectedUserId,
            groupIds: alreadySelectedGroupId
          }
        httpPost(OPTIVAL_CONSTANTS.API_URLS.SEARCH_GROUP_USERS,requestBody, queryParams).then(
          (response: any) => {
            if (response && response.data && !response.data?.error) {
              setGroupsList(response.data);
            } else {
              handleAlertBar(
                "error",
                response?.data?.message || t("opva.serviceUnavailable")
              );
            }
          },
          (error: any) => {
            handleAlertBar(
              "error",
              error.message || t("opva.serviceUnavailable")
            );
          }
        );
      }, 500),
    [assignedGroupsList,assignedUsersList]
  );

  return (
    <div className="assign-container">
      <Grid container>
        <Grid item xs={6} md={6}>
          <Typography variant="h6" className="mb-3">
            {t("opva.assignToGroupsUsers")}
          </Typography>
          <div className="d-flex">
            <Autocomplete
              id="search-by-group"
              className="me-5 w-50"
              autoComplete
              options={groupsList}
              noOptionsText={
                !groupsList ? (
                  <Typography variant="labelNormal">
                    {t("opva.type2Letters")}
                  </Typography>
                ) : (
                  <Typography variant="labelNormal">
                    {t("opva.noDataAvailable")}
                  </Typography>
                )
              }
              renderOption={(props, option: WorkflowAssignGroupsUsersModel) => (
                <li {...props} value={option?.employee?.id || option?.group?.groupId}>
                  {option.employee?.firstName || option?.group?.name}
                </li>
              )}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Regular option
                return option?.employee?.firstName || option?.group?.name;
              }}
              onChange={(event: any, newValue) => {
                if (newValue && typeof newValue === "object") {
                  if(newValue?.group) {
                    setSelectedGroupId(newValue?.group?.groupId);
                  } else {
                    setSelectedUserId(newValue?.employee?.id);
                  }
                }
              }}
              onInputChange={(event: any, newInputValue: string) => {
                // setSearchGroupName(newInputValue);
                fetchGroupUsersList(newInputValue);
              }}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  size="small"
                  variant="standard"
                  placeholder={t("opva.searchByGroupsUsers")}
                  className="search-name-text pe-0"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Button
              variant="contained"
              size="medium"
              className="py-2 w-35"
              onClick={handleAddWorkflowToGroups}
              disabled={!selectedGroupId && !selecteduserId}
            >
              {t("opva.addGroupUsers")}
            </Button>
          </div>
        </Grid>
        <Grid item xs={6} md={6}>
          <Link
            component="button"
            className="float-end"
            title="Close"
            onClick={props.collapseClose}
          >
            <CloseIcon />
          </Link>
        </Grid>
      </Grid>
      <Typography variant="body2" className="fw-bold mt-4 mb-3">
        {t("opva.assignedGroupUsers")}
      </Typography>
      <Grid container spacing={2} className="assigned-user-container">
        {isSpinnerShow ? (
          <AssignLoading />
        ) : (
          <>
            {!assignedGroupsList && !assignedUsersList ? (
              <Grid item xs={12} md={12}>
                <section className="d-flex align-items-center justify-content-center assigned-user h-100">
                  <Typography variant="labelNormal">
                    {t("opva.noAssignedGroups")}
                  </Typography>
                </section>
              </Grid>
            ) : (<>
            {assignedGroupsList.length > 0 && (
              assignedGroupsList.map(
                (data: any, index: number) => (
                  <Grid item xs={3} md={3} key={index}>
                    <section className="assigned-user">
                      <div className="d-flex mb-3">
                        <img src="images/User-Thumb-Black.svg" alt="User" />
                        <div className="ms-2">
                          <Typography
                            variant="bodySmall"
                            className="d-block fw-bold"
                          >
                            {data.name}
                          </Typography>
                          <Typography variant="labelSmall" className="d-block">
                            {t("opva.totalUsers:")} <b>{data?.usersAssigned}</b>
                          </Typography>
                        </div>
                      </div>
                      <div style={{ alignItems: "end" }}>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={openGroup ? "long-menu" : undefined}
                          aria-expanded={openGroup ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={(e) => handleGroupClick(e, data?.id)}
                          color="primary"
                        >
                          <MoreHorizIcon />
                        </IconButton>
                        <Menu
                          id="demo-positioned-menu"
                          aria-labelledby="demo-positioned-button"
                          anchorEl={anchorGroupEl}
                          open={openGroup}
                          onClose={handleGroupClose}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <MenuItem onClick={handleGroupClose}>
                            <Link component="button" title={t("opva.notify")}>
                              <NotificationsNoneIcon />
                            </Link>{" "}
                            <Typography
                              variant="bodySmall"
                              className="d-block ms-2"
                            >
                              {t("opva.notify")}
                            </Typography>
                          </MenuItem>
                          <MenuItem onClick={() => handleAlertGroupDialogOpen()}>
                            <Link
                              component="button"
                              title={t("opva.delete")}
                              onClick={() => handleAlertGroupDialogOpen()}
                            >
                              <DeleteOutlineIcon color="error" />
                            </Link>
                            <Typography
                              variant="bodySmall"
                              className="d-block ms-2"
                              onClick={() => handleAlertGroupDialogOpen()}
                            >
                              {t("opva.delete")}
                            </Typography>
                          </MenuItem>
                        </Menu>
                      </div>
                      {/* <div className="mb-3">
                        <Typography variant="labelSmall">
                        {t("opva.currentProgress")}
                        </Typography>
                        <Typography variant="labelSmall" className="float-end">
                          <b>{data.progress}%</b>
                        </Typography>
                      </div>
                      <div className="linear-progress-container mb-4">
                        <LinearProgress
                          variant="determinate"
                          value={data.progress || 0}
                        />
                      </div> */}
                      {/* <div className="d-flex justify-content-around">
                        <Link component="button" title={t("opva.notify")}>
                          <NotificationsNoneIcon />
                        </Link>
                        <div className="vertical-line"></div>
                        <Link
                          component="button"
                          title={t("opva.delete")}
                          onClick={() => handleAlertDialogOpen(data.id)}
                        >
                          <DeleteOutlineIcon color="error" />
                        </Link>
                      </div> */}
                    </section>
                  </Grid>
                )
              )
            )}
            {assignedUsersList?.length > 0 && (
              assignedUsersList?.map(
                (data: any, index: number) => (
                  <Grid item xs={3} md={3} key={index}>
                    <section className="assigned-user">
                      <div className="d-flex mb-3">
                        <img src="images/User-Thumb-Black.svg" alt="User" />
                        <div className="ms-2">
                          <Typography
                            variant="bodySmall"
                            className="d-block fw-bold"
                          >
                            {data?.firstName}
                          </Typography>
                        </div>
                      </div>
                      <div style={{ alignItems: "end" }}>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={openUser ? "long-menu" : undefined}
                          aria-expanded={openUser ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={(e)=>handleUserClick(e,data?.id)}
                          color="primary"
                        >
                          <MoreHorizIcon />
                        </IconButton>
                        <Menu
                          id="demo-positioned-menu"
                          aria-labelledby="demo-positioned-button"
                          anchorEl={anchorUserEl}
                          open={openUser}
                          onClose={handleUserClose}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <MenuItem onClick={handleUserClose}>
                            <Link component="button" title={t("opva.notify")}>
                              <NotificationsNoneIcon />
                            </Link>{" "}
                            <Typography
                              variant="bodySmall"
                              className="d-block ms-2"
                            >
                              {t("opva.notify")}
                            </Typography>
                          </MenuItem>
                          <MenuItem onClick={() => handleAlertUserDialogOpen()}>
                            <Link
                              component="button"
                              title={t("opva.delete")}
                              onClick={() => handleAlertUserDialogOpen()}
                            >
                              <DeleteOutlineIcon color="error" />
                            </Link>
                            <Typography
                              variant="bodySmall"
                              className="d-block ms-2"
                              onClick={() => handleAlertUserDialogOpen()}
                            >
                              {t("opva.delete")}
                            </Typography>
                          </MenuItem>
                        </Menu>
                      </div>
                    </section>
                  </Grid>
                )
              )
            )}
            </>)}
          </>
        )}
      </Grid>
      <AlertDialog
        open={isGroupAlertDialog}
        title={t("opva.deleteAssignGroup")}
        description={t("opva.deleteAssignGroupMsg")}
        submitBtnText={t("opva.delete")}
        handleSubmit={handleRemoveAssignedGroup}
        handleClose={handleAlertGroupDialogClose}
      ></AlertDialog>
      <AlertDialog
        open={isUserAlertDialog}
        title={t("opva.deleteAssignUser")}
        description={t("opva.deleteAssignUserMsg")}
        submitBtnText={t("opva.delete")}
        handleSubmit={handleRemoveAssignedGroup}
        handleClose={handleAlertUserDialogClose}
      ></AlertDialog>
    </div>
  );
};
