import HighchartsReact from "highcharts-react-official";
import "./PieChart.scss";
import Highcharts from "highcharts";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Typography } from "@mui/material";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { OPTIVAL_CONSTANTS } from "../../../shared/Constants";
import { useAPI } from "../../../shared/services/api/API";
export const PieChart = () => {
  const { httpGet, handleAlertBar } = useAPI();
  const [pieChartData, setPieChartData] = useState<any>([]);
  const [total, setTotal] = useState("");
  const { t } = useTranslation();
  const customColors = ['#1C1C1C', '#629B6A', '#25BCCA', '#0084F4']

  useEffect(() => {
    getUsersByGroupChartData();
  }, []);

  const getUsersByGroupChartData = () => {
    httpGet(
      OPTIVAL_CONSTANTS.API_URLS.GET_USERS_BY_GROUP
    ).then((response) => {
      if (response && response.data) {
        if (response.data.error) {
          handleAlertBar("error", response.data.message);
        } else {
          console.log(response.data?.groups)
          const result = response.data?.groups
          const pieChartSeriesData = result?.map((item: any, index: number) => {
            return {
              name: item.name,
              y: item.percentage,
              shortName: item.name.length > 12 ? `${item.name.substring(0,12)}...`: item.name,
              color: index === 0 ? { 
                linearGradient: {x1: 0, y1: 0, x2: 0, y2: 1 },
                stops: [
                  [0, customColors[index]],
                  [1, Highcharts.color(customColors[index]).setOpacity(0.4).get()],
                ]} : customColors[index],
            }
          })
          setPieChartData(pieChartSeriesData);
        }
      } else {
        handleAlertBar("error", t("opva.serviceUnavailable"));
      }
    });
  };

  const options = {
    chart: {
      renderTo: "container",
      type: "pie",
      plotBackgroundColor: "#F7F9FB",
      backgroundColor: "#F7F9FB",
      marginLeft: 0,
      height: 313,
    },
    title: {
      text: t("opva.userByGroups"),
      align: "left",
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      layout: "vertical",
      x: 0,
      y: 90,
      labelFormat: `<div style="display: flex; justify-content: space-between; width: 200px">
                      <span style="margin-bottom: 10px" title={name}>{shortName}</span>
                      <span style="margin-bottom: 10px">{y}%</span>
                    </div>`,
      useHTML: true,
    },
    tooltip: {
      pointFormat: "{series.name}",
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        data: pieChartData,
        name: t("opva.skillDistribution"),
        size: "100%",
        innerSize: "47%",
        showInLegend: true,
        dataLabels: {
          enabled: false,
        },
      },
    ],
  };

  return (
    <div className="pie-container p-2">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
