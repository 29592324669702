import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import "./BarChart.scss";
import { useTranslation } from "react-i18next";
import { OPTIVAL_CONSTANTS } from "../../../../shared/Constants";
import { useAPI } from "../../../../shared/services/api/API";
import { title } from "process";

export const BarChart = (props: any) => {
  const { t } = useTranslation();

  const options = {
    chart: {
      type: "column",
      backgroundColor: "#F7F9FB",
      height: props?.title === t("opva.workflowBySkills") ? 317 : 280
    },

    title: {
      text: props?.title,
      align: "left",
    },
    xAxis: {
      categories: props?.category,
      labels: {
        overflow: 'justify'
      },
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      minorTickLength: 0,
      tickLength: 0
      // scrollbar: {
      //   enabled: true
      // },
    },
    yAxis: {
      title: {
        text: null,
      },
      lineWidth: 0,
      gridLinewidth: 0,
      gridLineColor: 'transparent',
    },
    legend: {
      enabled: false
    },

    tooltip: {
      format: "{y}",
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
          borderRadius: {
              radius: 10
          },
          stacking: 'normal'
      }
  },
    series: props?.seriesData,
  };
  return (
    <div className="bar-container">
      <div className="p-4">
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </div>
  );
};
