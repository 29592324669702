import "./DashboardInfo.scss";
import { DashboardInfoProps } from "../../models/DashboardInfoProps";
import { useTranslation } from "react-i18next";

export const DashboardInfo = (props: DashboardInfoProps) => {
  const { t } = useTranslation(); 
  return (
    <div className={"dashboard-info-container " + props.bgColorName} onClick={props?.action} style={{filter: props?.isBorder ? "drop-shadow(0.5px 3px 3px #00000040)" : ''}}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex flex-column w-75">
          <label className="title-text">{t(props.title)}</label>
          <label className="name-text">
            <b>
              {props.infoName}&nbsp;
            </b>
          </label>
        </div>
        {props.infoSubName ? (
          <>
            <span className="sub-name-text mt-3">{props.infoSubName}%</span>
            {props.infoSubName > 0 ? <img className="mt-3" src={"images/" + 'ArrowUp.png'} alt="Profile" width={'20px'} height={'20px'}></img> : <img className="mt-3" src={"images/" + 'ArrowFall.png'} alt="Profile" width={'20px'} height={'20px'}></img>}
          </>
              ) : null}
        {props.iconName && <img src={"images/" + props.iconName} alt="Profile" width={'45px'} height={'45px'}></img>}
      </div>
    </div>
  );
};
