
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';

export const CopyCreateDialog = (props: any) => {
    const { t } = useTranslation();
    const radioOptions = [{label:t("opva.create_Workflow"),value: "Create Workflow"}, {label:t("opva.createVersion"), value: "Create Version"}];
    const { onClose, value, open, onSubmit } = props;
    const [radioValue, SetRadioValue] = React.useState(value);
    const radioGroupRef = React.useRef<HTMLElement>(null);

    useEffect(() => {
        if (!open) {
            SetRadioValue(value);
        }
    }, [value, open]);

    const handleEntering = () => {
        if (radioGroupRef.current != null) {
        radioGroupRef.current.focus();
        }
    };

    const handleCancel = () => {
        onClose();
    };

    const handleSubmit = () => {
        onSubmit(radioValue);
        onClose();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event?.target.value)
        SetRadioValue((event.target as HTMLInputElement).value);
    };
  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
      maxWidth="xs"
      TransitionProps={{ onEntering: handleEntering }}
      open={open}
    >
      <DialogTitle>{t("opva.copyCreate")}</DialogTitle>
      <DialogContent>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={radioValue}
          onChange={handleChange}
        >
          {radioOptions.map((option: any) => (
            <FormControlLabel
              value={option.value}
              key={option}
              control={<Radio />}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained"  onClick={handleCancel}>
          {t("opva.cancel")}
        </Button>
        <Button color="primary" variant="contained"  onClick={handleSubmit}>{t("opva.submit")}</Button>
      </DialogActions>
    </Dialog>
  )
}